
import { useState } from 'react'
import { ModalBox,InputBox } from '../../components'
import axios from 'axios'
import { validate } from '@/utils'
import { message } from 'antd'

import './layout.scss'

function CheckSeason({className}){

    const [status,setStatus] = useState(false)
    const [value,setValue] = useState('')
    const [data,setData] = useState({})
    // 0x29f22976680CB836c5E88a5787587DAA6De15D26
    // 0xdd3e53c2813ca30304d34a7146076833b38451bd
    // 0xc22a3e7ed61660ab7badc665a9d89d6b04e85080

    const showDialog = ()=>{
        setStatus(false)
    }

    const getPoint = ()=>{

        if(!validate.isContractAddr(value)){
            message.error({
                content: "Enter your EVM address",
            })
            return;
        }

        axios({
            method: 'post',
            url: 'https://api.quantlytica.com/api/dreward/queryuserpoint.do',
            data: `p=${JSON.stringify({"useraddr": value })}`
        }).then(function (response) {
            try {
                console.log(response.data.info.f_remark)
                const remark = response.data.info.f_remark
                const qtlx = remark ? JSON.parse(response.data.info.f_remark || "{}") : {QTLX: "0"}
                setData({QTLX: qtlx.QTLX || "0"})
            } catch (error) {
                message.error({
                    content: "Error.",
                })
            }
        }).catch(function (error) {
            message.error({
                content: "Error",
            })
        });
    }

    return (
        <>
            <div className={`flex align_center justify_center br_12 py_12 px_24 btn_b_2 fs_14 cursor ${className}`} onClick={_=>setStatus(true)}>
                <span className='zindex_0'>Check Season 1</span>
            </div>
            <ModalBox
                bodyClass="br_24"
                open={status}
                width={720}
                maskClosable={false}
                destroyOnClose={true}
                centered
                closeNative={true}
                onCancel={()=>showDialog(false)}
                footer={<span></span>}
            >
                <div className='checkSeasonBox flex column gap_24'>
                    <div className='mainTitle b_g_1 br_24 text_center fw_700'>Check Your QTLX Allocation</div>
                    <div className='checkInput py_12'>
                        <div className='text_3 py_12 fw_600 fs_14'>EVM Address</div>
                        <div className='checkInput_1 b_g_4 br_16 fw_700 fs_16'><InputBox value={value} placeholder="Enter your EVM address" onChange={setValue}  /></div>
                        <div className='text_3 py_12 fw_600 fs_14 mt_24'>Season 1 Allocation</div>
                        <div className='checkInput_2 px_24 b_g_1_1 br_16 flex justify_between align_center text_2'>
                            <span className='fw_700 fs_16'>QTLX Amount</span>
                            <div className='flex align_center'>
                                <img style={{width: "18px"}} src='/images/icon/qcoin.png' />
                                <span className='text_1 fw_600 ml_8'>{data?.QTLX||"--"}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex column gap_8'>
                        <div className='grid g_r_2 gap_24'>
                            <div className={`checkBtn flex align_center justify_center py_12 px_24 btn_b_2 cursor`} onClick={getPoint}>
                                <span className='zindex_0 text_g_1 fw_700'>Check</span>
                            </div>
                            <div className={`checkBtn flex align_center justify_center py_12 px_24 btn_b_2 opacity_3 cursor`} onClick={_=>showDialog(false)}>
                                <span className='zindex_0 text_g_1 fw_700'>Cancel</span>
                            </div>
                        </div>
                        <a href='https://docs.quantlytica.com/reward-program/season-1-completed' target='_blank' className='fs_12 text_3 text_center text_underline'>How is Season 1 allocation calculated?</a>
                    </div>
                </div>


            </ModalBox>
        </>
        
    )
}

function LaunchApp({className}){
    return (
        <a className={`flex align_center justify_center br_12 py_12 px_24 btn_b_2 fs_14 ${className}`} href='https://dapp.quantlytica.com'>
            <span className='zindex_0'>Launch App</span>
        </a>
    )
}

function MultiIcon({className}){
    return (
        <div className={`flex align_center gap_12 ${className}`}>
            <a className='flex align_center justify_center br_12 pa_12 btn_b_2' href="https://docs.quantlytica.com/" target='_blank'>
                <img className='zindex_0' src='/images/icon/gitbook.svg' />
            </a>
            <a className='flex align_center justify_center br_12 pa_12 btn_b_2' href="https://twitter.com/quantlytica" target="_blank">
                <img className='zindex_0' src='/images/icon/twitter.svg' />
            </a>
            <a className='flex align_center justify_center br_12 pa_12 btn_b_2' href="https://t.me/quantlytica" target="_blank">
                <img className='zindex_0' src='/images/icon/telegram.svg' />
            </a>
        </div>
    )
}

export function HeaderIcon({}){
    return (
        <div className='flex align_center gap_24'>
            <CheckSeason className="hide_s" />
            <LaunchApp />
            <MultiIcon className="hide_s" />
        </div>
    )
}

export function HomeIcon({className}){
    return (
        <div className={`flex align_center gap_24 mt_24`}>
            <CheckSeason className="hide_m hide_l" />
            <MultiIcon className="hide_m hide_l" />
        </div>
    )
}